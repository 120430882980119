.me {
    padding-bottom: 60px;
}

.me .section--start {
    padding: 10px 4%;
    width: 100%;
}

.me .section--start .detail--box {
    height: 180px;
    width: 100%;
    background: url('https://static.independent.co.uk/2021/04/05/05/urnpublicidap.orgd18f48cb71e64efe8119c9e0987f9639.jpg') 100% / cover;
    border-radius: 20px;
    margin-top: 15px;
    overflow: hidden;
}

.me .section--start .detail--box .detail--box--cover {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #0000008e;
    padding: 15px 15px;
    color: #fff
}

.me .section--start .detail--box .box--content .balance {
    margin-left: 10px;
}

.me .section--start .detail--box .box--content .balance .balance--top--text {
    font-size: 25px;
    font-weight: 700;
}

.me .section--start .detail--box .box--content .balance .balance--bottom--text {
    font-size: 20px;
    font-weight: 700;
    color: #ffffffaa
}

.me .section--start .detail--box .box--content .sub--col {
    margin-top: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 5px;
}

.me .section--start .detail--box .box--content .sub--col .col--top--text {
    font-size: 18px;
    font-weight: 700;
}

.me .section--start .detail--box .box--content .sub--col .col--bottom--text {
    font-size: 13px;
    color: #ffffffaa
}

.me .section--start .section--menu2 {
    margin-top: 40px;
}

.me .section--start .section--menu2 .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
}

.me .section--start .section--menu2 .col .col--left {
    display: flex;
    align-items: center;
    gap: 0 15px
}

.me .section--start .section--menu2 .col .col--left .col--icon {
    height: 35px;
    width: 35px;
    background: #000;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffffad;
    font-size: 20px;
}

.me .section--start .section--menu2 .col .col--left .item--name {
    font-size: 20px;
    font-weight: 500;
}

.me .section--start .section--menu2 .col .col--left .item--dec {
    margin-top: 2px;
    font-size: 15px;
}

.me .section--start .section--menu2 .col .col--right {
    display: flex;
    align-items: center;
    font-size: 25px;
    margin-right: 10px;
}





.home .main .button__con {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 7px;
    margin-top: 10px;
}

.home .main .button__con button {
    height: 55px;
    width: 100%;
    border-radius: 12px;
    border: none;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 15px;
}

.home .main .button__con button .icon {
    height: 30px;
    width: 30px;
    background: #000;
    border-radius: 100px;
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home .main .button__con button.recharge__btn {
    background: #000;
    color: #fff;
}

.home .main .button__con button.recharge__btn .icon {
    background: #fff;
    color: #000;
}

.home .main .button__con button.withdraw__btn {
    background: transparent;
    border: 3px solid #000;
}


.home .main .me__menu {
    margin-top: 40px;
}

.home .main .me__menu .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
}

.home .main .me__menu .col .col__left {
    display: flex;
    align-items: center;
    gap: 0 10px
}

.home .main .me__menu .col .col__left .icon {
    height: 27px;
    width: 27px;
    background: #000;
    border-radius: 100px;
}

.home .main .me__menu .col .col__left .item {
    font-size: 20px;
    font-weight: 500;
}

.home .main .me__menu .col .col__right {
    display: flex;
    align-items: center;
    font-size: 25px;
    margin-right: 10px;
}