.records .section--start .records--filter {
    padding: 0 0%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin-top: 10px;
}

.records .section--start .records--filter .item .item--name {
    font-size: 18px;
    font-weight: 600;
    color: #00000095
}

.records .section--start .records--filter .item.item--active .item--name {
    color: #000
}

.records .section--start .records--filter .item.item--active .dash {
    height: 3px;
    width: 100px;
    background: #000;
    margin-top: 6px;
}

.records .section--start .records--container {
    padding: 10px 5px;
}

.records .section--start .records--container .record--box {
    height: 70px;
    width: 100%;
    border-radius: 15px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.records .section--start .records--container .record--box .box--left {
    display: flex;
    align-items: center;
    gap: 0 15px;
}

.records .section--start .records--container .record--box .box--left .box--icon {
    background: linear-gradient(45deg, #000, #000000a5);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    color: #fff;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.records .section--start .records--container .record--box .box--left--right--col .box--col--top {
    font-size: 20px;
    font-weight: 600;
}

.records .section--start .records--container .record--box .box--left--right--col .box--col--bottom {
    font-size: 14px;
    margin-top: 2px;
}

.records .section--start .records--container .record--box .item--amount {
    font-size: 22px;
    font-weight: 800;
}


.records .section--start .records--container .record--box2 {
    padding: 15px 5%;
    background: #f8f8f8;
    border-radius: 20px;
    margin-top: 15px;
}

.records .section--start .records--container .record--box2 .box--top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.records .section--start .records--container .record--box2 .box--top .text--top {
    font-size: 18px;
    font-weight: 400;
}

.records .section--start .records--container .record--box2 .box--top .text--bottom {
    font-size: 15px;
    font-weight: 600;
}

.records .section--start .records--container .record--box2 .box--top .box--top--right .order--amount {
    font-size: 25px;
    font-weight: 700;
}

.records .section--start .records--container .record--box2 .box--bottom {
    margin-top: 20px;
}

.records .section--start .records--container .record--box2 .box--bottom .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
}

.records .section--start .records--container .record--box2 .box--bottom .col .col--right {
    font-weight: 500;
}

.records .no--data {
    margin-top: 100px;
}

.records .no--data .no--data--img img {
    height: 120px;
}

.records .no--data .no--data--text {
    font-size: 17px;
    font-weight: 500;
    color: #0000008f;
    margin-top: 15px;
}

/*.records .header {
    padding: 20px 4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.records .header .col1 {
    flex-basis: 60px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 40px;
}

.records .header .col2 {
    flex-basis: calc(100% - 120px);
    width: 100%;
    font-size: 25px;
    font-weight: 600;
}

.records .menu {
    padding: 0 2%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin-top: 15px;
}

.records .menu .col .col__name {
    font-size: 18px;
    font-weight: 600;
    color: #00000095
}

.records .menu .col.ac .col__name {
    color: #000
}

.records .menu .col .active {
    height: 3px;
    width: 100px;
    background: #000;
    margin-top: 6px;
}

.records .container {
    margin-top: 10px;
    padding: 10px 4%;
    padding-bottom: 30px;
}

.records .box {
    height: 70px;
    width: 100%;
    border-radius: 15px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.records .box .box__left {
    display: flex;
    align-items: center;
    gap: 0 15px;
}

.records .box .box__left .icon {
    background: linear-gradient(45deg, #000, #000000a5);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    color: #fff;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.records .box .box__left .left__col .left__tp {
    font-size: 20px;
    font-weight: 600;
}

.records .box .box__left .left__col .left__bp {
    font-size: 14px;
    margin-top: 2px;
}

.records .box .amount {
    font-size: 22px;
    font-weight: 800;
}



.records .box2 {
    padding: 15px 5%;
    background: #f8f8f8;
    border-radius: 20px;
    margin-top: 15px;
}

.records .box2 .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.records .box2 .top .top__left .tp__txt {
    font-size: 18px;
    font-weight: 400;
}

.records .box2 .top .top__left .bp__txt {
    font-size: 15px;
    font-weight: 600;
}

.records .box2 .top .amount {
    font-size: 25px;
    font-weight: 700;
}

.records .box2 .bottom {
    margin-top: 20px;
}

.records .box2 .bottom .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
}

.records .box2 .bottom .col .col__right {
    font-weight: 500;
} */