.crypto--pay .crypto--pay--top {
    padding: 30px 5%;
    width: 100%;
    background: #f5f5f5;
    gap: 0 15px;
    color: #000
}

.crypto--pay .crypto--pay--top .order--details {
    display: flex;
    gap: 0 20px
}

.crypto--pay .crypto--pay--top .order--details--left {
    flex-basis: 60px;
    width: 100%;
}

.crypto--pay .crypto--pay--top .order--details--left .platform--logo {
    aspect-ratio: 1 / 1;
    width: 100%;
}

.crypto--pay .crypto--pay--top .order--details--right {
    flex-basis: calc(100% - 130px);
    width: 100%;
}

.crypto--pay .crypto--pay--top .order--details--right .platform--name {
    font-size: 25px;
    font-weight: 500;
}

.crypto--pay .crypto--pay--top .order--details--right .order--id {
    font-size: 13px;
    font-weight: 500;
    color: #444;
    margin-top: 3px;
}

.crypto--pay .crypto--pay--bottom {
    padding: 15px 5%;
    margin-top: 20px;
}

.crypto--pay .crypto--pay--bottom .payment--details--box {
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 10px;
}

.crypto--pay .crypto--pay--bottom .payment--details--box .box--top {
    display: flex;
    align-items: center;
    padding: 10px 6%;
    width: 100%;
    justify-content: space-between;
    color: #dae0ff;
    background: rgb(72, 124, 255);
}

.crypto--pay .crypto--pay--bottom .payment--details--box .box--top .box--top--right {
    font-weight: 700;
    letter-spacing: 0.4px;
    color: rgb(9, 1, 93);
}

.crypto--pay .crypto--pay--bottom .payment--details--box .box--top.sc {
    background: rgb(37, 227, 101);
}

.crypto--pay .crypto--pay--bottom .payment--details--box .box--top.fc {
    background: rgb(227, 62, 37);
}

.crypto--pay .crypto--pay--bottom .payment--details--box .box--top.sc .box--top--left {
    color: green
}

.crypto--pay .crypto--pay--bottom .payment--details--box .box--top.fc .box--top--left {
    color: rgb(137, 0, 0)
}

.crypto--pay .crypto--pay--bottom .payment--details--box .box--bottom {
    padding: 15px 6%;
}

.crypto--pay .crypto--pay--bottom .payment--details--box .box--bottom .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.crypto--pay .payment--details--box .box--bottom .col .col--left {
    flex-basis: calc(100% - 24px);
    width: 100%;
    overflow: hidden;
}

.crypto--pay .payment--details--box .box--bottom .col .col--left .col--left--top {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #555
}

.crypto--pay .payment--details--box .box--bottom .col .col--left .col--left--bottom {
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #000
}

.crypto--pay .payment--details--box .box--bottom .col .col--left .col--left--bottom span {
    font-size: 13px;
    color: #333;
}

.crypto--pay .payment--details--box .box--bottom .col.m2 {
    margin-top: 25px;
}

.crypto--pay .payment--details--box .box--bottom .col .col--right {
    flex-basis: 22px;
    width: 100%;
    font-size: 22px;
    margin-top: 30px;
    color: #000
}

.crypto--pay .payment--details--box .box--bottom .si--txt {
    font-size: 14px;
    margin-top: 20px;
    color: red;
}

.crypto--pay .bt--txt {
    margin-top: 15px;
}

.crypto--pay .bt--txt span {
    color: blue;
    font-weight: 600;
}