.auth {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    background: url('https://images.unsplash.com/photo-1585187012096-3e57104ee03d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHNwYWNleHxlbnwwfHwwfHx8MA%3D%3D');
    background-size: cover;
    overflow-x: scroll;
}

.auth .auth--section {
    padding: 50px 4%;
}

.auth .auth--section .section--top {
    margin-left: 10px;
}

.auth .auth--section .section--top .des--text {
    margin-top: 25px;
    color: #fff
}

.auth .auth--section .section--top .des--text--top {
    font-size: 18px;
}

.auth .auth--section .section--top .des--text--bottom {
    font-size: 25px;
    font-weight: 600;
}


.auth .auth--form {
    width: 100%;
    background: #fff;
    margin-top: 40px;
    border-radius: 20px;
    padding: 20px 6%;
    padding-bottom: 35px;
}

.auth .auth--form .form--title {
    font-size: 25px;
    font-weight: 600;
    color: #000000;
}

.auth .auth--form .col {
    margin-top: 15px;
}

.auth .auth--form .col .form--input {
    height: 45px;
    width: 100%;
    background: #f1f1f1;
    border-radius: 7px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.auth .auth--form .col .form--input .in--left {
    flex-basis: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: #555;
    font-weight: 500;
}

.auth .auth--form .col .form--input.type2 .in--left .selector--icon {
    display: flex;
    align-items: center;
}

.auth .auth--form .col .form--input.type2 .in--left {
    font-size: 17px;
}

.auth .auth--form .col .form--input .in--left .lf--icon {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.auth .auth--form .col .form--input input {
    flex-basis: calc(100% - 60px);
    width: 100%;
    border: none;
    outline: none;
    height: 100%;
    background: transparent;
    font-size: 18px;
    padding: 0 0px;
    font-weight: 500;
}

.auth .auth--form .col .form--input.type3 input {
    padding-left: 15px;
}

.auth .auth--form .col .form--input.type2 .in--left {
    flex-basis: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0 4px;
}

.auth .auth--form .col .form--input.type2 .input {
    flex-basis: calc(100% - 80px);
    width: 100%;
}

.auth .auth--form .col .form--input input::placeholder {
    color: #555;
    font-size: 17px;
}

.auth button {
    height: 50px;
    width: 100%;
    background: #000000;
    border: none;
    border-radius: 7px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

.auth .login--bp {
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 0 5px;
    justify-content: center;
    margin-top: 12px;
    color: #000
}

.auth .login--bp .bpii {
    text-decoration: underline;
    font-weight: 500;
}

.auth .col--title {
    color: #000
}