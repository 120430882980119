.investment .section--start .investment--filter {
    padding: 0 2%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin-top: 10px;
}

.investment .top--box .box--cover {
    color: #fff;
    padding: 20px 20px;
}

.investment .top--box .box--cover .col.m2 {
    margin-top: 15px;
}

.investment .top--box .box--cover .col .col--top {
    font-weight: 500;
    color: #ffffff8c;
    font-size: 16px;
}

.investment .top--box .box--cover .col .col--bottom {
    font-weight: 700;
    color: #fff;
    font-size: 25px;
}

.investment .section--start .investment--filter .item .item--name {
    font-size: 18px;
    font-weight: 600;
    color: #00000095
}

.investment .section--start .investment--filter .item.item--active .item--name {
    color: #000
}

.investment .section--start .investment--filter .item.item--active .dash {
    height: 3px;
    width: 100px;
    background: #000;
    margin-top: 6px;
}

.investment .no--data {
    margin-top: 50px;
}

.investment .no--data .no--data--img img {
    height: 170px;
}

.investment .no--data .no--data--text {
    font-size: 17px;
    font-weight: 500;
    color: #0000008f;
    margin-top: 15px;
}

.investment .section--start .records--container {
    padding: 20px 0;
    padding-bottom: 35px;
}

.investment .section--start .record--box {
    width: 100%;
    background: #f4f4f4;
    border-radius: 20px;
    padding: 10px 5px;
    margin-top: 40px;
    display: flex;
    gap: 0 10px;
}

.investment .section--start .record--box .record--box--left {
    flex-basis: 35%;
    width: 100%;
    height: 150px;
    background: linear-gradient(rgba(16, 16, 16, 0.714), rgb(0, 0, 0));
    border-radius: 15px;
    margin-top: -35px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.investment .section--start .record--box .record--box--left .box--left--top {
    font-size: 13px;
    font-family: Poppins;
    font-weight: 500;
    color: #ffffffb5;
}

.investment .section--start .record--box .record--box--left .box--left--bottom {
    font-size: 27px;
    margin-top: 5px;
    font-weight: 800;
    color: #ffffffc8
}

.investment .section--start .record--box .record--box--right {
    flex-basis: calc(65% - 25px);
    width: 100%;
}

.investment .section--start .record--box .record--box--right .box--col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 3px;
}

.investment .section--start .record--box .record--box--right .box--col--top {
    font-size: 14px;
    color: #333;
    font-weight: 500;
}

.investment .section--start .record--box .record--box--right .box--col--bottom {
    font-size: 17px;
    font-weight: 700;
    color: rgb(0, 0, 0)
}