.withdraw .section--start .col {
    margin-top: 20px;
}

.withdraw .section--start .col .col--title {
    font-size: 20px;
}

.withdraw .section--start .col .sub--col {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px 10px;
    margin-top: 10px;
}

.withdraw .section--start .col .sub--col .item {
    height: 45px;
    width: 100%;
    border-radius: 8px;
    border: 1.2px solid #bbb;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
}

.withdraw .section--start .col .input--field {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    background: transparent;
    border: 1.2px solid #bbb;
    border-radius: 8px;
    overflow: hidden;
}

.withdraw .section--start .col .input--field.type1 {
    display: flex;
    align-items: center;
}

.withdraw .section--start .col .input--field.type2 {
    padding: 0 20px;
}

.withdraw .section--start .col .input--field .input--field--icon {
    flex-basis: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
}

.withdraw .section--start .col .input--field input {
    flex-basis: calc(100% - 60px);
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: 22px;
    font-weight: 500;
}

.withdraw .section--start .confirm--withdraw {
    margin-top: 35px;
}

.withdraw .section--start .withdraw--btn {
    height: 55px;
    width: 80%;
    border-radius: 100px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    background: #000;
}

.withdraw .section--start .withdraw--sp--text {
    font-size: 18px;
    margin-top: 10px;
    text-decoration: underline;
}

.withdraw .top--box .box--cover {
    color: #fff;
    padding: 20px 20px;
}

.withdraw .top--box .box--cover .col.m2 {
    margin-top: 15px;
}

.withdraw .top--box .box--cover .col .col--top {
    font-weight: 500;
    color: #ffffff8c;
    font-size: 16px;
}

.withdraw .top--box .box--cover .col .col--bottom {
    font-weight: 700;
    color: #fff;
    font-size: 25px;
}

.withdraw .section--start .col .sub--col .item.item--active {
    background: #000000c4;
    border: 1.2px solid #000000;
    color: #fff;
}

.withdraw .section--start .col .sub--col .item .item--active--sign {
    clip-path: polygon(100% 52%, 39% 100%, 100% 100%);
    position: absolute;
    height: 65px;
    width: 60px;
    background: #000;
    bottom: 0;
    right: 0;
    color: #ffffff;
    font-size: 15px;
}

.withdraw .section--start .col .sub--col .item .item--active--sign .check--sign {
    margin-top: 45px;
    margin-left: 43px;
}

.withdraw .withdraw--notes {
    margin-top: 25px;
}

.withdraw .withdraw--notes .col4 {
    margin-top: 15px;
}