@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
  outline: none;
}

.pg {
  min-height: 100vh;
  background: #00000011;
  width: 100%;
}

.section--start {
  min-height: 550px;
  width: 100%;
  background: #fff;
  border-radius: 15px 15px 0 0;
  margin-top: -5px;
  padding: 10px 4%;
  padding-bottom: 25px;
}

.top--box {
  padding: 5px 4%;
}

.top--box .box {
  height: 170px;
  width: 100%;
  background: url('https://static.independent.co.uk/2021/04/05/05/urnpublicidap.orgd18f48cb71e64efe8119c9e0987f9639.jpg') 100% / cover;
  border-radius: 30px 30px 0 0;
  position: relative;
  overflow: hidden;
}

.top--box .box .box--cover {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0000008e;
  padding: 15px 15px;
}