/* Header CSS */
.main--header {
    padding: 15px 4% 15px 4%;
    justify-content: space-between;
}

.main--header,
.main--header .header--right {
    display: flex;
    align-items: center;
}

.main--header .header--left .app--logo img {
    height: 70px;
}

.main--header .header--right {
    gap: 0 15px;
}

.main--header .header--right .user--detail {
    text-align: right;
}

.main--header .header--right .user--detail .user--name {
    font-size: 20px;
    font-weight: 600;
}

.main--header .header--right .user--detail .user--phone {
    font-size: 14px;
}

.main--header .header--right .user--pic {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    overflow: hidden;
}

.main--header .header--right .user--pic img {
    height: 100%;
    width: 100%;
}


/* Top Bar CSS */
.main--top {
    padding: 0 2%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin-top: 15px;
}

.main--top .item .item--name {
    font-size: 18px;
    font-weight: 600;
    color: #00000095
}

.main--top .item.item--active .item--name {
    color: #000
}

.main--top .item--active .dash {
    height: 3px;
    width: 100px;
    background: #000;
    margin-top: 6px;
}


/* Section Title CSS */
.section--title {
    display: flex;
    align-items: center;
    gap: 0 3px;
}

.section--title .section--title--left {
    height: 20px;
    width: 30px;
    background: #000;
}

.section--title .section--title--right {
    font-size: 25px;
    font-weight: 700;
}


/* Top2 CSS */
.top--bar {
    padding: 20px 4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top--bar .corner--col {
    flex-basis: 60px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 40px;
}

.top--bar .center--col {
    flex-basis: calc(100% - 120px);
    width: 100%;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}


/* Cash Prize CSS */
.redeem--code--popup {
    width: 88%;
    padding: 20px 8%;
    background: #ffffff;
    border-radius: 20px;
    margin-left: 6%;
    margin-top: 60%;
    padding-bottom: 25px;
}

.redeem--code--popup .popup--top--text {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
}

.redeem--code--popup .popup--input {
    margin-top: 25px;
}

.redeem--code--popup .popup--input span {
    font-size: 17px;
    font-weight: 500;
}

.redeem--code--popup .popup--input input {
    margin-top: 5px;
    width: 100%;
    height: 55px;
    border-radius: 15px;
    border: 1.5px solid #000;
    padding: 0 15px;
    font-size: 18px;
    font-weight: 600;
}

.redeem--code--popup .confirm--btn {
    margin-top: 15px;
    width: 100%;
    height: 55px;
    background: #000;
    border-radius: 15px;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    border: none;
}


/* Loading & Error */
.over--screen {
    height: 100%;
    width: 100%;
    background: #00000035;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.over--screen .loading--screen {
    height: 100%;
    width: 100%;
}

.over--screen .loading--screen .loader--img {
    position: absolute;
    top: calc((100% - 110px) / 2);
    left: calc((100% - 110px) / 2);
    height: 110px;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
}

.over--screen .loading--screen .loader--img img {
    margin-top: -10px;
    margin-left: 60px;
}

.over--screen .loading--screen .loader {
    width: 150px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #000000;
    position: absolute;
    top: calc((100% - 150px) / 2);
    left: calc((100% - 150px) / 2);
    animation:
        l20-1 0.8s infinite linear alternate,
        l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%)
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%)
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%)
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%)
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%)
    }
}

@keyframes l20-2 {
    0% {
        transform: scaleY(1) rotate(0deg)
    }

    49.99% {
        transform: scaleY(1) rotate(135deg)
    }

    50% {
        transform: scaleY(-1) rotate(0deg)
    }

    100% {
        transform: scaleY(-1) rotate(-135deg)
    }
}

.error--screen {
    position: fixed;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10000;
}

.error--screen .error--message {
    background: rgb(0, 0, 0);
    border-radius: 15px;
    padding: 15px 8%;
    font-weight: 700;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.858);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
    max-width: 88%;
}

/* Country Popup */
.over--screen .country--popup {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 30px 30px 0 0;
    width: 100%;
    padding: 10px 5%;
}

.over--screen .country--popup .header {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f5f5f5;
}

.over--screen .country--popup .header .header--left {
    font-size: 22px;
    font-weight: 600;
}

.over--screen .country--popup .header .header--right {
    font-size: 30px;
    display: flex;
    align-items: center;
    margin-right: 2px;
}

.over--screen .country--popup .list--container {
    height: 350px;
    width: 100%;
    position: relative;
    padding: 10px 0;
    overflow-x: scroll;
}

.over--screen .country--popup .list--container .list--col {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
}

.over--screen .country--popup .list--container .list--col.active {
    background: #000;
    border-radius: 15px;
    color: #fff;
    padding: 0 20px;
}

.over--screen .country--popup .list--container .list--col--left {
    font-size: 18px;
    font-weight: 500;
}

.over--screen .country--popup .list--container .list--col--right {
    font-size: 20px;
    font-weight: 700;
}

.over--screen .popup--close--bg {
    height: 100%;
    width: 100%;
}