.home {
    padding-bottom: 25px;
}

.home .section--start {
    padding: 10px 4%;
}

.home .section--start .home--middle--menu {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0 5px;
    width: 100%;
    margin-top: 30px;
}

.home .section--start .notification--box {
    height: 40px;
    width: 100%;
    border-radius: 7px;
    background: #f5f5f5;
    margin-top: 15px;
}

.home .section--start .notification--box,
.home .section--start .home--middle--menu .col .col--icon,
.home .section--start .notification--box .notification--icon {
    display: flex;
    align-items: center;
}

.home .section--start .notification--box .notification--icon {
    flex-basis: 50px;
    width: 100%;
    justify-content: center;
    font-size: 22px;
    color: #555
}

.home .section--start .notification--box .notification--text {
    flex-basis: calc(100% - 50px);
    width: 100%;
    padding-right: 10px;
}

.home .section--start .home--middle--menu .col .col--icon {
    height: 50px;
    width: 50px;
    background: linear-gradient(45deg, #000, #000000a5);
    border-radius: 70px;
    justify-content: center;
    font-size: 35px;
    color: #fff;
}

.home .section--start .home--middle--menu .col .col--name {
    font-size: 13px;
    margin-top: 7px;
    font-weight: 500;
}

.home .section--start .plan--section {
    margin-top: 40px;
}

.home .section--start .plan--section .col {
    margin-top: 30px;
}

.home .section--start .plan--section .col .col--top {
    width: 100%;
    height: 160px;
    background: url('https://cdn.arstechnica.net/wp-content/uploads/2024/03/starshipwdr-ift3.jpeg');
    background-size: cover;
    border-radius: 3px;
}

.home .section--start .plan--section .col .plan--details {
    margin-top: 15px;
    justify-content: space-between;
    gap: 0 15px;
}

.home .section--start .plan--section .col .plan--details,
.home .section--start .plan--section .col .plan--details--left {
    display: flex;
    align-items: center;
}

.home .section--start .plan--section .col .plan--details--left {
    gap: 0 30px;
}

.home .section--start .plan--section .col .plan--details--left .sub--col--top {
    font-size: 20px;
    font-weight: 700;
}

.home .section--start .plan--section .col .plan--details--left .sub--col--bottom {
    font-size: 14px;
    color: #333;
}

.home .section--start .plan--section .col .plan--details--right .purchase--btn {
    height: 45px;
    width: 110px;
    background: #000;
    color: #fff;
    border: none;
    font-size: 18px;
    font-weight: 500;
    border-radius: 80px;
}

.home .redeem--screen,
.me .redeem--screen,
.home .investment--popup {
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    height: 100%;
}

.home .redeem--screen .redeem--popup--back,
.me .redeem--screen .redeem--popup--back,
.home .investment--popup .investment--popup--back {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: #00000052;
    z-index: -1;
}

.home .investment--popup .investment--popup--box {
    padding-bottom: 15px;
    width: 90%;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 35%;
    margin-left: 5%;
}

.home .investment--popup .investment--popup--box .investment--box--top {
    background: url('https://cdn.arstechnica.net/wp-content/uploads/2024/03/starshipwdr-ift3.jpeg');
    width: 100%;
    height: 175px;
    background-size: cover;
}

.home .investment--popup .investment--popup--box .investment--plan--details {
    padding: 15px 5%;
}

.home .investment--popup .investment--popup--box .investment--plan--details .description {
    font-size: 13px;
}

.home .investment--popup .investment--popup--box .investment--plan--details .description span {
    font-weight: 600;
}

.home .investment--popup .investment--popup--box .investment--plan--details .grid--col {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 15px;
    margin-top: 30px;
}

.home .investment--popup .investment--popup--box .investment--plan--details .grid--col .col {
    text-align: center;
}

.home .investment--popup .investment--popup--box .investment--plan--details .grid--col .col--top {
    font-size: 15px;
    font-weight: 700;
}

.home .investment--popup .investment--popup--box .investment--plan--details .grid--col .col--bottom {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 5px;
}

.home .investment--popup .investment--popup--box .investment--plan--details .confirm--invest--sec {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
}

.home .investment--popup .investment--popup--box .investment--plan--details .plan--cost--top {
    font-size: 20px;
    font-weight: 700;
}

.home .investment--popup .investment--popup--box .investment--plan--details .plan--cost--bottom {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.home .investment--popup .investment--popup--box .investment--plan--details .confirm--invest--btn {
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 100px;
    padding: 10px 30px;
    font-weight: 500;
    background: #000;
}