.tr .section--start .record--filter {
    padding: 0 2%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin-top: 10px;
}

.tr .top--box .box--cover {
    color: #fff;
    padding: 20px 20px;
}

.tr .top--box .box--cover .col.m2 {
    margin-top: 15px;
}

.tr .top--box .box--cover .col .col--top {
    font-weight: 500;
    color: #ffffff8c;
    font-size: 16px;
}

.tr .top--box .box--cover .col .col--bottom {
    font-weight: 700;
    color: #fff;
    font-size: 25px;
}

.tr .section--start .record--filter .item .item--name {
    font-size: 18px;
    font-weight: 600;
    color: #00000095
}

.tr .section--start .record--filter .item.item--active .item--name {
    color: #000
}

.tr .section--start .record--filter .item.item--active .dash {
    height: 3px;
    width: 100px;
    background: #000;
    margin-top: 6px;
}

.tr .section--start .records--container {
    padding: 15px 2%;
}

.tr .section--start .records--container .record--box, .tr .section--start .records--container .record--box2 {
    width: 100%;
    border-radius: 20px;
    background: #f5f5f5;
    margin-top: 10px;
    padding: 15px 5%;
}

.tr .section--start .records--container .record--box .record--box--top .user--details {
    display: flex;
    align-items: center;
    gap: 0 15px;
}

.tr .section--start .records--container .record--box--top .user--pic {
    height: 50px;
    width: 50px;
    background: #ccc;
    border-radius: 40px;
    overflow: hidden;
}

.tr .section--start .records--container .record--box--top .user--name {
    font-size: 23px;
    font-weight: 800;
}

.tr .section--start .records--container .record--box--top .user--ph {
    font-size: 13px;
}

.tr .section--start .records--container .record--box--bottom {
    margin-top: 20px;
}

.tr .section--start .records--container .record--box--bottom .col {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tr .section--start .records--container .record--box--bottom .col .col--right {
    font-weight: 600;
}

.tr .section--start .records--container .record--box--bottom .col .col--left {
    color: #222;
    font-weight: 400;
}

.tr .section--start .records--container .record--box2 .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tr .section--start .records--container .record--box2 .col .col--left .ph--col {
    font-size: 18px;
    font-weight: 600;
}

.tr .section--start .records--container .record--box2 .col .col--left .date--col {
    font-size: 14px;
    margin-top: 7px;
}

.tr .section--start .records--container .record--box2 .col .amount--col {
    font-weight: 800;
    font-size: 25px;
}

.tr .no--data {
    margin-top: 50px;
}

.tr .no--data .no--data--img img {
    height: 120px;
}

.tr .no--data .no--data--text {
    font-size: 17px;
    font-weight: 500;
    color: #0000008f;
    margin-top: 15px;
}