.team {
    padding-bottom: 60px;
}

.team .section--start {
    padding: 10px 4%;
    width: 100%;
}

/*.team .section--start,
.team .section--start .detail--box,
.team .section--start .detail--box .detail--box--cover .box--content {
    position: relative;
} */

.team .section--start .detail--box {
    height: 180px;
    width: 100%;
    background: url('https://static.independent.co.uk/2021/04/05/05/urnpublicidap.orgd18f48cb71e64efe8119c9e0987f9639.jpg') 100% / cover;
    border-radius: 20px;
    margin-top: 15px;
    overflow: hidden;
}

.team .section--start .detail--box .detail--box--cover {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #0000008e;
    padding: 15px 15px;
}

.team .section--start .detail--box .detail--box--cover .box--content {
    z-index: 5;
}

.team .detail--box--cover .box--content--top .top--text {
    font-size: 17px;
    font-weight: 500;
    color: #ffffffaf;
}

.team .detail--box--cover .box--content--top .bottom--col,
.team .detail--box--cover .box--content--bottom .bottom--col {
    display: flex;
    align-items: center;
    gap: 0 10px;
}

.team .detail--box--cover .box--content--top .bottom--col .bottom--col--text {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.4px;
    color: #fff
}

.team .detail--box--cover .box--content--top .bottom--col .copy--btn,
.team .detail--box--cover .box--content--bottom .bottom--col .copy--btn {
    padding: 5px 15px;
    border-radius: 100px;
    background: #000000;
    border: none;
    font-size: 14px;
    color: #ffffff;
    outline: none;
    font-weight: 600;
}

.team .detail--box--cover .box--content--bottom .bottom--col .copy--btn {
    margin-left: -90px;
    margin-top: 10px;
}

.team .detail--box--cover .box--content--bottom {
    margin-top: 25px;
}

.team .detail--box--cover .box--content--bottom .top--text {
    font-size: 13px;
    color: #ffffffb0
}

.team .detail--box--cover .box--content--bottom .bottom--col--text {
    font-size: 15px;
    font-weight: 500;
    margin-top: 3px;
    gap: 0 0px;
    color: #fff;
}

.team .section--start .referral--data {
    margin-top: 20px;
}

.team .referral--data .col--container {
    margin-top: 15px;
    overflow-x: auto;
    white-space: nowrap;
}

.team .referral--data .col--container::-webkit-scrollbar {
    display: none;
}

.team .referral--data .col--container .col {
    display: inline-flex;
    gap: 0 15px;
}

.team .referral--data .col--container .col .data--box {
    width: 250px;
    background: #f5f5f5;
    border-radius: 12px;
    padding: 15px 15px;
}

.team .referral--data .data--box .data--box--top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.team .referral--data .data--box .data--box--top .box--text--top {
    font-size: 40px;
    font-weight: 700;
}

.team .referral--data .data--box .data--box--top .box--text--bottom {
    font-size: 15px;
    font-weight: 500;
    color: #000000a6
}

.team .referral--data .data--box .team--view--btn {
    padding: 5px 22px;
    border-radius: 100px;
    background: #000;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: none;
}

.team .referral--data .data--box .data--box--bottom .sub--col {
    margin-top: 15px;
    margin-left: 5px;
}

.team .referral--data .data--box .data--box--bottom .sub--col--top {
    font-size: 20px;
    font-weight: 700;
}

.team .reward--section {
    margin-top: 30px;
}

.team .reward--section .reward--box {
    width: 100%;
    border-radius: 15px;
    background: #f5f5f5;
    margin-top: 20px;
    padding: 15px 15px;
}

.team .reward--section .reward--box .reward--box--top {

    justify-content: space-between;
}

.team .reward--section .reward--box .reward--box--top,
.team .reward--section .reward--box .reward--box--top .reward--level,
.team .reward--section .reward--box .reward--level .reward--level--icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.team .reward--section .reward--box .reward--box--top .reward--level {
    gap: 0 10px
}

.team .reward--section .reward--box .reward--level .reward--level--icon {
    height: 30px;
    width: 30px;
    background: #000;
    border-radius: 100px;
    justify-content: center;
    font-size: 22px;
    color: #fff;
}

.team .reward--section .reward--box .reward--level .reward--level--text {
    font-size: 25px;
    font-weight: 600;
}

.team .reward--section .reward--box .reward--box--top .box--top--right {
    font-size: 27px;
    font-weight: 700;
}

.team .reward--section .reward--box .reward--box--top .box--top--right span {
    font-size: 18px;
    color: #333
}

.team .reward--section .reward--box .reward--box--bottom .reward--condition {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
}

.team .reward--section .reward--box .reward--box--bottom .reward--condition span {
    font-weight: 600;
}

.team .reward--section .reward--box .reward--box--bottom .reward--claim {
    margin-top: 30px;
}

.team .reward--section .reward--box .reward--box--bottom .reward--claim--btn {
    background: #000;
    height: 40px;
    width: 80%;
    border: none;
    border-radius: 100px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.team .reward--section .reward--box .reward--box--bottom .reward--claim--btn.disabled {
    background: #00000076;
    color: #ffffffa0;
}